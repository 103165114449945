import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import BookDownloadForm from '../../components/BookDownloadForm'
import Img from "gatsby-image";
import HowItWorks from "../../components/HowItWorks";

const StopForeclosure = ({data}) => {
  
  const { bookImage, teamImage } = data;

 
  return (
    <Layout headerTransparent={false}>
      <SEO
        title={'Free Guide: 5 Ways You Can Stop or Avoid Foreclosure In Today&#8217;s Market | Sell Your Home For Cash Sacramento'}
        description={'Free Guide: 5 Ways You Can Stop or Avoid Foreclosure In Today&#039;s Market Learn how to avoid foreclosure, stop the foreclosure process, and your 5 main foreclosure'}
        keywords={''}
      />


<section className="py-16 section-stripe-bg bg-gray-50 ">
   <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
      <div className="w-full md:w-2/3 md:pr-4 prose">
        

       
         
         
             <h1><strong><span className="text-red-700">FREE Guide:</span></strong> 5 Ways You Can Stop or Avoid Foreclosure In Today’s Market</h1>
            <p>  <figure class="float-right"> <Img
              className="rounded-lg shadow-lg object-cover object-center ml-8" 
              fixed={bookImage.childImageSharp.fixed}
              width={80}
              alt="Sell Your Home For Cash Sacramento is a professional home buyer in Sacramento, CA."
            /> </figure><strong>Learn how to avoid foreclosure, stop the foreclosure process, and your 5 main foreclosure avoidance options with this free guide.&nbsp;</strong></p>
<p>After reading this simple to follow 6 page guide you’ll know:</p>
<ul>
<li>How you may be able to still save your credit during your foreclosure</li>
<li>The 5 ways some banks are working with borrowers to lower payments or even sometimes eliminate payments (if you qualify)</li>
<li>Why a “Notice of Default” can limit your options (and what to do this week if your bank has already filed a Notice of Default against you)</li>
<li>The 5 options to stop a foreclosure that’s already happened</li>
</ul>
<p>In the end, foreclosure is never fun and doesn’t always end well.</p>
<p>But, when you’re armed with the right information, know all of your options, and can make a well educated plan… your odds of coming out of this foreclosure situation with as little long-term damage as possible increase a lot.</p>
<p><strong>Download the Free Foreclosure Guide by entering your information to the right</strong>. We’ll email you the pdf of the guide so you can go through it at your own leisure. You’ll also receive other tips and help from our Foreclosure Avoidance team to help you make a well informed decision.</p>
 
      </div>
      <div className="w-full md:w-1/3 md:pl-4">
         <div className="rounded-xl mb-2 shadow bg-white  mt-6 md:mt-0">
            <div className="px-8 pt-8">
               <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Download Your FREE Stop Foreclosure Guide Below</h2>
               <p class="text-gray-600 mb-2 leading-6 mt-1">Just put in your name and email, click "Submit" and we'll email you the Free Guide right away.</p>
            </div>
            <div className="p-8 pt-4">
               <BookDownloadForm stacked={true} hideLabels={true} showPlaceholders={true} />
               <span className="block text-xs text-gray-600 text-center mt-2">We never rent, sell, or share your info with anyone. It's not cool and we hate spam</span>
            </div>
         </div>
      </div>
   </div>
</section>



      <HowItWorks />

    </Layout>
  );
};


export default StopForeclosure;



export const pageQuery = graphql`
  query StopForeclosure {

    bookImage: file(relativePath: { eq: "5-ways-foreclosure.png" }) {
      childImageSharp {
        fixed(width: 230) {
          ...GatsbyImageSharpFixed
          src
        }
      }
    }
  }
`;
